import { CSSProperties, useState } from "react"
import { useNavigate } from "react-router-dom"
import { useTranslation } from 'react-i18next'
import { HashLoader } from "react-spinners"
import DatePicker from "react-datepicker"

import { DeployLogsAPI } from "stores/apis"
import { Wrapper, TextEditor } from "components"
import useStyles from './styles'
import PasswordAuthentication from './components/password-authentication'
import "react-datepicker/dist/react-datepicker.css"
import './datePicker.css'
import moment from "moment"
import { DeployLogSources, DeployLogSourceText } from "stores/models/deploy-log.models"

const override: CSSProperties = {
  display: "block",
  margin: "0 auto",
}

const EditLog = () => {
  const [state, setState] = useState({
    source: undefined,
    contentEN: '',
    contentNO: '',
    deployedAt: new Date(),
    version: '',
    isSaving: false,
    isAuthorized: false,
  })
  const handleStateChange = (newState: any) => setState(prevState => ({ ...prevState, ...newState }))
  const navigate: any = useNavigate()


  const styles = useStyles()
  const { t }: any = useTranslation()

  const onChangeContentEN = (contentEN: string) => handleStateChange({ contentEN })
  const onChangeContentNO = (contentNO: string) => handleStateChange({ contentNO })
  const onChangeDeployedAt = (val: Date) => handleStateChange({ deployedAt: val })
  const onChangeVersion = (e: any) => handleStateChange({ version: e.target.value })
  const onChangeSource = (e: any) => handleStateChange({ source: e.target.value })
  const onAuthenticated = () => handleStateChange({ isAuthorized: true })

  const onSaveLog = async () => {
    handleStateChange({ isSaving: true })

    const response = await DeployLogsAPI.addDeployLog({
      source: Number(state.source),
      content_en: state.contentEN,
      content_no: state.contentNO,
      deployed_at: moment(state.deployedAt).format('YYYY-MM-DD HH:mm:ss'),
      version: state.version
    })

    handleStateChange({ isSaving: false })
    if (response?.status === 200) {
      navigate(-1)
    }
  }

  if (!state.isAuthorized) return <PasswordAuthentication onAuthenticated={onAuthenticated} />

  const isValid = !state.isSaving && state.contentEN && state.contentNO && state.source

  return (
    <Wrapper className={styles.container}>
      <h2 className={styles.title}>{t('home.addDeployLog')}</h2>
      <div>
        <label className={styles.label}>
          Application:
        </label>
        <div>
          <select value={state.source} className={styles.select} onChange={onChangeSource}>
            {DeployLogSources.map((s: any) => <option key={s} value={s}>{DeployLogSourceText[s]}</option>)}
          </select>
        </div>

        <label className={styles.label}>
          {t('home.english')}:
        </label>
        
        <TextEditor data={state?.contentEN} onChange={onChangeContentEN} />

        <label className={styles.label}>
          {t('home.norway')}:
        </label>
        
        <TextEditor data={state?.contentNO} onChange={onChangeContentNO} />

        <label className={styles.label}>
          Deploy Date:
        </label>
        <DatePicker selected={state.deployedAt} onChange={onChangeDeployedAt} dateFormat="dd.MM.yyyy" />

        <label className={styles.label}>
          Version (optional):
        </label>

        <div>
          <input type="text" className={styles.inputVersion} value={state.version} onChange={onChangeVersion} />
        </div>

        <button disabled={state.isSaving || !state.contentEN || !state.contentNO} onClick={onSaveLog} className={isValid ? styles.buttonSave : styles.buttonSaveDisabled}>
          {state.isSaving ? (
            <HashLoader loading={true} cssOverride={override} size={20} color="#fff" />
          ) : <p>{t('common.save')}</p>}
        </button>
      </div>
    </Wrapper>
  )
}

export default EditLog

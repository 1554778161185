
import { takeLatest, put, call } from 'redux-saga/effects'
import { AxiosResponse } from 'axios'
import * as actions from 'stores/actions'
import { UsersAPI } from 'stores/apis'

export function * getUserActionsWorker (action: any) {
  try {
    const response: AxiosResponse | undefined = yield call(UsersAPI.getUserActions)

    if (response?.data?.data) {
      yield put({ type: actions.GET_USER_ACTIONS_SUCCESS, payload: response.data.data })
    } else {
      yield put({ type: actions.GET_USER_ACTIONS_FAILED })
    }
  } catch (error) {
    yield put({ type: actions.GET_USER_ACTIONS_FAILED })
  }
}

function * getUserActionsWatcher () {
  yield takeLatest(actions.GET_USER_ACTIONS_REQUEST, getUserActionsWorker)
}

export default getUserActionsWatcher
